import React from 'react';
import './HomeLayout.scss'
const HomeLayout = ({children}) => {
    return (
        <div className="HomeLayout">
            {children}
        </div>
    );
};

export default HomeLayout;